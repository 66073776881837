import styles from "../assets/styles";
import styled from "styled-components";
import bgCitcle from "../assets/image/bg-circle.svg";
import bgright from "../assets/image/ic-bgstick.svg";
import ICMobile from "../assets/image/ic-mobile-logo.svg";
import myphoto from "../assets/image/my-photo.png";
import "./css/MobileLanding.css";
export const HeaderLanding = () => {
  return (
    <>
      <Container className="parent-container">
        <HeadingContainer className="Heading">
          <BGCircle className="BGCircle" src={bgCitcle} alt="bg-color" />
          <GreetingBox className="GreetingBox">
            <Name className="Name">
              I'm Web and
              <span style={{ color: `${styles.dark}` }}>
                <br /> Mobile <br />
                Developer
              </span>
            </Name>
            <HeadingName className="HeadingName">
              Turning the designs into an actual application for 3 years.
              &nbsp;
              <img src={ICMobile} alt="mobilelogo" /> JS/TS.
            </HeadingName>
          </GreetingBox>
          <img
            className="myphoto example-style"
            src={myphoto}
            style={{ marginTop: "-50px", zIndex: 999, flex: 0.5 }}
            alt="Mark Angelo F Ignacio"
          />
        </HeadingContainer>
        <BgRight className="BGCircle" src={bgright} />
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: ${styles.cherry};
  display: flex;
  height: 62vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 73px 0;

.example-style {
  animation: fade-in 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
  }
`;
const HeadingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BGCircle = styled.img`
  position: absolute;
  z-index: 0;
  top: -50px;
  left: -80px;
`;
const BgRight = styled.img`
  position: absolute;
  top: 32%;
  right: 0px;
`;
const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6%;
  gap: 18px;
  border: 1px solid red;
`;

const GreetingBox = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  transform: rotate(-4deg);
`;
const Name = styled.h1`
  padding: 0;
  margin: 0;
  color: ${styles.white};
  font-family: ${styles.black};
  text-transform: uppercase;
  font-size: 3.875rem;
  align-center: center;
`;
const HeadingName = styled.h2`
  padding: 0;
  width: 496px;
  margin: 14px 0 0 0;
  font-family: ${styles.medium};
  font-size: 2.25rem;
  color: ${styles.white};
  text-align: left;
  text-transform: lowercase;
`;
